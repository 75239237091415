import { Flex, Text, Button, Link, Heading, ButtonVariant } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import BubbleImages from '../BubbleImages'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
  variant?: ButtonVariant
}

export interface SalesSectionProps {
  isDark?: boolean
  headingText: string
  bodyText: string
  type?: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton?: SalesSectionButton
  images?: CompositeImageProps
  noAnimation?: boolean
}

const SalesSection: React.FC<SalesSectionProps> = (props) => {
  const { isDark, headingText, bodyText, reverse, primaryButton, secondaryButton, images, type, noAnimation } = props

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
      >
        <Flex
          height={['192px', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
        >
          {type === 'bubble' ? <BubbleImages /> : <CompositeImage {...images} animate={!noAnimation} />}
        </Flex>
        <Flex
          style={{ gridGap: 32 }}
          flexDirection="column"
          flex="1"
          maxWidth="529px"
          ml={[null, null, null, reverse && '80px']}
          mr={[null, null, null, !reverse && '80px']}
          alignSelf={['flex-start', null, null, 'center']}
        >
          <Heading scale="xl" color={isDark ? 'white' : 'text'}>
            {headingText}
          </Heading>
          <Text color={isDark ? 'rgba(255,255,255,0.8)' : 'darkGray'} mb="24px">
            {bodyText}
          </Text>
          <Flex>
            <Button scale="lg" mr="16px" width="216px" variant={(primaryButton.variant ?? 'primary') as ButtonVariant}>
              {primaryButton.external ? (
                <Link external href={primaryButton.to}>
                  {primaryButton.text}
                </Link>
              ) : (
                <RouterLink to={primaryButton.to}>{primaryButton.text}</RouterLink>
              )}
            </Button>
            {secondaryButton &&
              (secondaryButton.external ? (
                <Link external href={secondaryButton.to}>
                  {secondaryButton.text}
                </Link>
              ) : (
                <RouterLink to={secondaryButton.to}>{secondaryButton.text}</RouterLink>
              ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
