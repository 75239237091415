import styled, { keyframes } from 'styled-components'
import { Box } from '@pancakeswap/uikit'

const floatingAnim = (x: string, y: string) => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(${x}, ${y});
  }
  to {
    transform: translate(0, 0px);
  }
`

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  height: 210px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 310px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 310px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 310px;
  }

  & :nth-child(2) {
    animation: ${floatingAnim('3px', '5px')} 3s ease-in-out infinite;
    animation-delay: 1s;
    margin-top: -10px;
    img {
      max-width: 100px;
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 200px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        max-width: 300px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        max-width: 300px;
      }
    }
  }

  & :nth-child(3) {
    animation: ${floatingAnim('5px', '10px')} 3s ease-in-out infinite;
    animation-delay: 0.66s;
    right: 10px;
    top: 25px;

    ${({ theme }) => theme.mediaQueries.md} {
      right: 35px;
      top: 15px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      right: 15px;
      top: -25px;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      right: 15px;
      top: -25px;
    }

    img {
      max-width: 52px;
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 72px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        max-width: 92px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        max-width: 92px;
      }
    }
  }

  & :nth-child(4) {
    animation: ${floatingAnim('6px', '5px')} 3s ease-in-out infinite;
    animation-delay: 0.33s;

    top: auto;

    bottom: 50px;
    right: 0;

    ${({ theme }) => theme.mediaQueries.md} {
      bottom: 30px;
      right: 30px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      bottom: 30px;
      right: -30px;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      bottom: 30px;
      right: -30px;
    }

    img {
      max-width: 50px;
      width: 100%;
      height: auto;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 95px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        max-width: 125px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        max-width: 125px;
      }
    }
  }

  & :nth-child(5) {
    animation: ${floatingAnim('4px', '3px')} 3s ease-in-out infinite;
    animation-delay: 0s;

    top: auto;

    bottom: 45px;
    left: 30px;

    ${({ theme }) => theme.mediaQueries.md} {
      bottom: 15px;
      left: 100px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      left: 70px;
      bottom: 0;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      left: 70px;
      bottom: 0;
    }

    img {
      max-width: 50px;
      width: 100%;
      height: auto;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 90px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        max-width: 130px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        max-width: 130px;
      }
    }
  }
`

const DummyImg = styled.img`
  max-width: 300px;
  width: 100%;
  visibility: hidden;
`
const ImageWrapper = styled(Box)`
  position: absolute;

  img {
    max-height: 100%;
    width: auto;
  }
`

enum Resolution {
  MD = '1.5x',
  LG = '2x',
}

const images = ['main-bubble', 'bubble-1', 'bubble-2', 'bubble-3']
const path = '/images/home/BTC-bubbles/'

export const getImageUrl = (base: string, imageSrc: string, resolution?: Resolution, extension = '.png'): string =>
  `${base}${imageSrc}${resolution ? `@${resolution}${extension}` : extension}`

export const getSrcSet = (base: string, imageSrc: string, extension = '.png') => {
  return `${getImageUrl(base, imageSrc, undefined, extension)} 512w,
  ${getImageUrl(base, imageSrc, Resolution.MD, extension)} 768w,
  ${getImageUrl(base, imageSrc, Resolution.LG, extension)} 1024w,`
}

const BubbleImages: React.FC = () => {
  return (
    <Wrapper position="relative" width="100%">
      <picture>
        <source type="image/webp" srcSet={getSrcSet(path, images[0], '.webp')} />
        <source type="image/png" srcSet={getSrcSet(path, images[0])} />
        <DummyImg src={getImageUrl(path, images[0])} loading="lazy" decoding="async" />
      </picture>
      {images.map((image) => (
        <ImageWrapper key={image}>
          <picture>
            <source type="image/webp" srcSet={getSrcSet(path, image, '.webp')} />
            <source type="image/png" srcSet={getSrcSet(path, image)} />
            <img src={getImageUrl(path, image)} alt={image} loading="lazy" decoding="async" />
          </picture>
        </ImageWrapper>
      ))}
    </Wrapper>
  )
}

export default BubbleImages
