import { Svg, SvgProps } from '@pancakeswap/uikit'

const SunburstSvg: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1440 911" {...props}>
      <g opacity="0.5">
        <rect y="92" width="1440" height="789" fill="#F9F9F9" />
        <path d="M1049.27 706.717L811.91 595.183L812.046 594.885L1049.41 706.419L1049.27 706.717Z" fill="#FFBA33" />
        <path d="M1061.85 712.631L816.111 597.162L816.247 596.836L1061.98 712.333L1061.85 712.631Z" fill="#FFBA33" />
        <path d="M1475.82 902.287L1091.39 725.988L1091.26 726.287L1475.82 902.667V902.287Z" fill="#FFBA33" />
        <path d="M1475.82 902.286L1099.79 729.842L1099.66 730.14L1475.82 902.666V902.286Z" fill="#FFBA33" />
        <path d="M1475.82 570.616L1381.55 511.307L1381.36 511.578L1475.82 571.023V570.616Z" fill="#FFBA33" />
        <path d="M1475.82 512.989L1391.15 440.379L1390.93 440.623L1475.82 513.423V512.989Z" fill="#FFBA33" />
        <path d="M1475.82 512.991L1384.13 434.355L1383.91 434.6L1475.82 513.425V512.991Z" fill="#FFBA33" />
        <path d="M1364.07 472.71L1363.74 472.655L1368.97 439.92L1369.3 439.974L1364.07 472.71Z" fill="#FFBA33" />
        <path d="M1426.22 555.959L1375.48 511.715L1375.72 511.443L1426.44 555.688L1426.22 555.959Z" fill="#FFBA33" />
        <path d="M1383.26 441.289L1382.96 441.48L1456.65 555.58L1456.95 555.389L1383.26 441.289Z" fill="#FFBA33" />
        <path d="M1422.72 552.92L1371.98 508.676L1372.23 508.404L1422.94 552.649L1422.72 552.92Z" fill="#FFBA33" />
        <path d="M1380.62 437.507L1380.33 437.699L1454.09 551.745L1454.38 551.553L1380.62 437.507Z" fill="#FFBA33" />
        <path d="M881.651 911.001L852.92 764.479L852.595 764.533L881.299 911.001H881.651Z" fill="#FFBA33" />
        <path d="M1464.68 911.001L892.792 743.85L892.683 744.175L1463.46 911.001H1464.68Z" fill="#FFBA33" />
        <path d="M881.652 910.999L849.369 746.264L849.017 746.345L881.299 910.999H881.652Z" fill="#FFBA33" />
        <path d="M1464.68 910.999L861.675 734.754L861.594 735.08L1463.46 910.999H1464.68Z" fill="#FFBA33" />
        <path d="M1475.82 409.87L1409.31 361.717L1409.09 362.015L1475.82 410.277V409.87Z" fill="#FFBA33" />
        <path d="M1475.82 387.396L1410.96 358.76L1410.82 359.058L1475.82 387.776V387.396Z" fill="#FFBA33" />
        <path d="M1475.82 409.871L1416.79 367.146L1416.6 367.445L1475.82 410.278V409.871Z" fill="#FFBA33" />
        <path d="M1475.82 387.395L1419.42 362.504L1419.28 362.802L1475.82 387.775V387.395Z" fill="#FFBA33" />
        <path d="M1475.82 466.846L1402.5 453.844L1402.42 454.17L1475.82 467.199V466.846Z" fill="#FFBA33" />
        <path d="M1475.82 466.845L1393.37 452.215L1393.31 452.568L1475.82 467.198V466.845Z" fill="#FFBA33" />
        <path d="M667.088 541.139L570.214 496.162L570.376 495.836L667.223 540.813L667.088 541.139Z" fill="#FFBA33" />
        <path d="M671.397 542.792L502.857 461.605L503.02 461.279L671.56 542.494L671.397 542.792Z" fill="#FFBA33" />
        <path d="M671.154 543.391L506.3 469.994L506.436 469.695L671.289 543.065L671.154 543.391Z" fill="#FFBA33" />
        <path d="M663.212 538.476L598.431 506.392L598.593 506.094L663.347 538.178L663.212 538.476Z" fill="#FFBA33" />
        <path d="M713.702 561.736L713.572 562.035L1061.64 713.123L1061.77 712.824L713.702 561.736Z" fill="#FFBA33" />
        <path d="M663.482 537.935L642.91 527.24L643.072 526.941L663.618 537.636L663.482 537.935Z" fill="#FFBA33" />
        <path d="M675.49 545.019L574.415 498.115L574.578 497.789L675.626 544.721L675.49 545.019Z" fill="#FFBA33" />
        <path d="M675.571 544.802L507.032 463.615L507.194 463.289L675.734 544.503L675.571 544.802Z" fill="#FFBA33" />
        <path d="M768.759 578.599L705.55 557.074L705.658 556.775L768.867 578.273L768.759 578.599Z" fill="#FFBA33" />
        <path d="M510.697 471.586L510.554 471.908L675.389 545.285L675.532 544.963L510.697 471.586Z" fill="#FFBA33" />
        <path d="M675.652 544.666L610.898 512.582L611.034 512.256L675.788 544.34L675.652 544.666Z" fill="#FFBA33" />
        <path d="M675.789 544.37L655.243 533.675L655.406 533.35L675.952 544.071L675.789 544.37Z" fill="#FFBA33" />
        <path d="M1147.25 910.998L703.11 561.576L702.893 561.848L1146.71 910.998H1147.25Z" fill="#FFBA33" />
        <path d="M1147.25 911L706.743 564.455L706.526 564.727L1146.71 911H1147.25Z" fill="#FFBA33" />
        <path d="M1297.77 723.274L1242.12 693.416L1242.29 693.117L1297.93 722.948L1297.77 723.274Z" fill="#FFBA33" />
        <path d="M1475.82 790.265L1331.79 737.742L1331.65 738.068L1475.82 790.618V790.265Z" fill="#FFBA33" />
        <path d="M1475.82 780.683L1243.31 690.783L1243.21 691.109L1475.82 781.063V780.683Z" fill="#FFBA33" />
        <path d="M1002.02 587.148L1001.89 587.447L1204.63 675.239L1204.76 674.94L1002.02 587.148Z" fill="#FFBA33" />
        <path d="M1475.82 780.683L1239.01 689.127L1238.87 689.426L1475.82 781.063V780.683Z" fill="#FFBA33" />
        <path d="M1006.28 588.998L1006.15 589.297L1208.9 677.089L1209.02 676.79L1006.28 588.998Z" fill="#FFBA33" />
        <path d="M1475.82 890.181L713.708 561.633L713.573 561.931L1475.82 890.561V890.181Z" fill="#FFBA33" />
        <path d="M781.905 583.075L709.914 558.564L710.023 558.266L782.014 582.749L781.905 583.075Z" fill="#FFBA33" />
        <path d="M454.892 436.547L454.86 436.871L458.852 437.27L458.884 436.946L454.892 436.547Z" fill="#FFBA33" />
        <path d="M485.477 445.587L485.315 445.9L671.689 542.266L671.851 541.953L485.477 445.587Z" fill="#FFBA33" />
        <path d="M496.324 440.735L496.292 441.059L500.31 441.461L500.342 441.137L496.324 440.735Z" fill="#FFBA33" />
        <path d="M1475.82 890.182L705.197 557.969L705.062 558.267L1475.82 890.562V890.182Z" fill="#FFBA33" />
        <path d="M493.668 449.894L493.507 450.207L675.742 544.443L675.904 544.13L493.668 449.894Z" fill="#FFBA33" />
        <path d="M675.923 544.098L506.489 451.945L506.652 451.646L676.086 543.8L675.923 544.098Z" fill="#FFBA33" />
        <path d="M-67 805.057V805.437L29.6026 855.598L29.7652 855.3L-67 805.057Z" fill="#FFBA33" />
        <path d="M166.863 911L88.7732 881.82L88.6377 882.146L165.887 911H166.863Z" fill="#FFBA33" />
        <path d="M-67 805.057V805.437L46.0554 864.149L46.1909 863.823L-67 805.057Z" fill="#FFBA33" />
        <path d="M166.863 911.001L75.763 876.963L75.6274 877.289L165.887 911.001H166.863Z" fill="#FFBA33" />
        <path d="M1189.65 906.333L677.388 582.154L677.577 581.883L1189.84 906.034L1189.65 906.333Z" fill="#FFBA33" />
        <path d="M659.878 568.663L659.552 568.69L658.468 554.711L658.821 554.684L659.878 568.663Z" fill="#FFBA33" />
        <path d="M1170.05 911.001L675.084 598.006L674.894 598.304L1169.4 911.001H1170.05Z" fill="#FFBA33" />
        <path d="M1170.56 911L1125.16 880.762L1124.97 881.06L1169.94 911H1170.56Z" fill="#FFBA33" />
        <path d="M1166.28 911L1121.02 878.645L1120.83 878.916L1165.71 911H1166.28Z" fill="#FFBA33" />
        <path d="M723.972 758.284L723.846 758.613L786.313 782.576L786.439 782.246L723.972 758.284Z" fill="#FFBA33" />
        <path d="M968.524 870.611L816.03 795.558L816.193 795.26L968.659 870.312L968.524 870.611Z" fill="#FFBA33" />
        <path d="M719.356 757.53L719.21 757.852L972.437 873.182L972.583 872.861L719.356 757.53Z" fill="#FFBA33" />
        <path d="M972.372 873.324L756.534 776.312L756.67 776.014L972.508 872.998L972.372 873.324Z" fill="#FFBA33" />
        <path d="M972.915 872.185L759.462 760.217L759.625 759.918L973.077 871.886L972.915 872.185Z" fill="#FFBA33" />
        <path d="M755.049 773.87L754.903 774.191L972.451 873.214L972.597 872.892L755.049 773.87Z" fill="#FFBA33" />
        <path d="M973.917 863.199L973.673 863.443L857.175 742.192L857.419 741.947L973.917 863.199Z" fill="#FFBA33" />
        <path d="M976.818 874.709L824.352 799.656L824.514 799.357L976.98 874.41L976.818 874.709Z" fill="#FFBA33" />
        <path d="M723.546 759.448L723.4 759.77L976.627 875.1L976.773 874.779L723.546 759.448Z" fill="#FFBA33" />
        <path d="M976.6 875.223L760.762 778.211L760.898 777.912L976.736 874.897L976.6 875.223Z" fill="#FFBA33" />
        <path d="M977.007 874.327L763.554 762.359L763.717 762.061L977.17 874.029L977.007 874.327Z" fill="#FFBA33" />
        <path d="M759.264 775.8L759.119 776.121L976.667 875.143L976.812 874.822L759.264 775.8Z" fill="#FFBA33" />
        <path d="M980.341 869.904L980.097 870.121L863.599 748.87L863.843 748.652L980.341 869.904Z" fill="#FFBA33" />
        <path d="M1138.55 910.999L863.898 741.188L863.708 741.486L1137.9 910.999H1138.55Z" fill="#FFBA33" />
        <path d="M1138.55 911.001L859.941 738.773L859.778 739.045L1137.9 911.001H1138.55Z" fill="#FFBA33" />
        <path d="M-67 799.465V799.845L156.536 910.999H157.322L-67 799.465Z" fill="#FFBA33" />
        <path d="M-67 799.465V799.845L156.536 910.999H157.322L-67 799.465Z" fill="#FFBA33" />
        <path d="M-67 780.086V780.439L242.703 911H243.57L-67 780.086Z" fill="#FFBA33" />
        <path d="M-67 780.086V780.439L242.703 911H243.57L-67 780.086Z" fill="#FFBA33" />
        <path d="M1063.01 911L865.741 746.562L865.524 746.834L1062.47 911H1063.01Z" fill="#FFBA33" />
        <path d="M1063.01 911.001L858.639 740.619L858.422 740.891L1062.47 911.001H1063.01Z" fill="#FFBA33" />
        <path d="M889.295 250.184L670.774 79.8027L670.964 79.5312L889.512 249.913L889.295 250.184Z" fill="#FFBA33" />
        <path d="M1376.54 345.052L673.159 75.6501L673.268 75.3516L1376.65 344.754L1376.54 345.052Z" fill="#FFBA33" />
        <path d="M1475.82 386.583L1415.27 360.471L1415.13 360.769L1475.82 386.936V386.583Z" fill="#FFBA33" />
        <path d="M358.794 0L886.992 253.984L887.154 253.686L359.581 0H358.794Z" fill="#FFBA33" />
        <path d="M431.923 0L1376.43 345.35L1376.54 345.052L432.899 0H431.923Z" fill="#FFBA33" />
        <path d="M892.955 253.034L674.406 82.6523L674.623 82.3809L893.172 252.762L892.955 253.034Z" fill="#FFBA33" />
        <path d="M358.794 0L891.166 255.993L891.328 255.695L359.581 0H358.794Z" fill="#FFBA33" />
        <path d="M1475.82 578.978L920.114 270.598L919.951 270.896L1475.82 579.358V578.978Z" fill="#FFBA33" />
        <path d="M1380.87 346.709L677.469 77.3064L677.604 77.0078L1380.98 346.41L1380.87 346.709Z" fill="#FFBA33" />
        <path d="M1475.82 386.583L1411.01 358.625L1410.88 358.951L1475.82 386.936V386.583Z" fill="#FFBA33" />
        <path d="M1475.82 326.811L1411.45 347.277L1411.56 347.603L1475.82 327.163V326.811Z" fill="#FFBA33" />
        <path d="M490.2 0L1380.9 346.626L1381.01 346.328L491.148 0H490.2Z" fill="#FFBA33" />
        <path d="M201.016 0H200.229L1475.82 635.898V635.518L201.016 0Z" fill="#FFBA33" />
        <path d="M1475.82 389.567L1415 361.066L1414.86 361.365L1475.82 389.947V389.567Z" fill="#FFBA33" />
        <path d="M1475.82 578.977L924.152 272.85L923.99 273.148L1475.82 579.357V578.977Z" fill="#FFBA33" />
        <path d="M201.016 0H200.229L1475.82 635.898V635.518L201.016 0Z" fill="#FFBA33" />
        <path d="M480.36 0L638.627 62.2407L638.735 61.915L481.282 0H480.36Z" fill="#FFBA33" />
        <path d="M490.2 0L1372.25 343.288L1372.39 342.962L491.148 0H490.2Z" fill="#FFBA33" />
        <path d="M1475.82 589.807L1389.49 466.547L1389.22 466.737L1475.82 590.404V589.807Z" fill="#FFBA33" />
        <path d="M1475.82 589.808L1386.84 462.748L1386.57 462.938L1475.82 590.405V589.808Z" fill="#FFBA33" />
        <path d="M1475.82 745.207L1304.98 911.001H1305.47L1475.82 745.668V745.207Z" fill="#FFBA33" />
        <path d="M1475.82 745.207L1304.98 911.001H1305.47L1475.82 745.668V745.207Z" fill="#FFBA33" />
        <path d="M-67 790.916V791.269L559.318 911H561.134L-67 790.916Z" fill="#FFBA33" />
        <path d="M985.681 130.075L945.132 124.13L945.186 123.777L985.735 129.749L985.681 130.075Z" fill="#FFBA33" />
        <path d="M1360.46 437.395L942.124 131.295L942.313 131.023L1360.65 437.124L1360.46 437.395Z" fill="#FFBA33" />
        <path d="M922.092 117.751L914.991 113.652L915.153 113.354L922.255 117.452L922.092 117.751Z" fill="#FFBA33" />
        <path d="M1425.38 455.338L1155.49 259.957L1155.68 259.686L1425.57 455.067L1425.38 455.338Z" fill="#FFBA33" />
        <path d="M1156.7 257.996L1156.54 258.281L1184.32 273.713L1184.48 273.429L1156.7 257.996Z" fill="#FFBA33" />
        <path d="M1475.82 378.465L1157.64 255.992L1157.53 256.291L1475.82 378.818V378.465Z" fill="#FFBA33" />
        <path d="M1128.17 242.639L984.38 163.135L984.543 162.836L1128.34 242.34L1128.17 242.639Z" fill="#FFBA33" />
        <path d="M1361.39 436.201L1154.71 260.934L1154.93 260.689L1361.6 435.957L1361.39 436.201Z" fill="#FFBA33" />
        <path d="M1429.12 458.053L1159.23 262.672L1159.42 262.4L1429.31 457.781L1429.12 458.053Z" fill="#FFBA33" />
        <path d="M1305.68 395.121L1305.52 395.434L1428 460.032L1428.16 459.72L1305.68 395.121Z" fill="#FFBA33" />
        <path d="M1426.68 463.292L1406.38 457.701L1406.46 457.375L1426.76 462.967L1426.68 463.292Z" fill="#FFBA33" />
        <path d="M955.243 192.096L949.117 189.572L949.225 189.246L955.378 191.77L955.243 192.096Z" fill="#FFBA33" />
        <path d="M1359.57 438.75L983.973 206.806L984.162 206.508L1359.73 438.452L1359.57 438.75Z" fill="#FFBA33" />
        <path d="M1385.78 468.667L1385.49 468.797L1464.65 651.765L1464.95 651.635L1385.78 468.667Z" fill="#FFBA33" />
        <path d="M1475.82 597.49L1391.63 470.701L1391.34 470.891L1475.82 598.114V597.49Z" fill="#FFBA33" />
        <path d="M1297.49 390.819L1297.32 391.131L1419.8 455.729L1419.96 455.417L1297.49 390.819Z" fill="#FFBA33" />
        <path d="M1360.22 437.722L1296.2 392.826L1296.39 392.555L1360.41 437.451L1360.22 437.722Z" fill="#FFBA33" />
        <path d="M950.472 197.445L946.352 194.269L946.541 193.998L950.688 197.174L950.472 197.445Z" fill="#FFBA33" />
        <path d="M1359.32 439.188L977.333 215.496L977.522 215.225L1359.49 438.889L1359.32 439.188Z" fill="#FFBA33" />
        <path d="M1475.82 400.777L1396 440.19L1396.16 440.489L1475.82 401.157V400.777Z" fill="#FFBA33" />
        <path d="M1475.82 558.32L1397.71 471.812L1397.43 472.03L1475.82 558.835V558.32Z" fill="#FFBA33" />
        <path d="M1188.37 276.025L1160.59 260.58L1160.75 260.281L1188.54 275.726L1188.37 276.025Z" fill="#FFBA33" />
        <path d="M1362.14 435.332L1214.29 295.026L1214.5 294.781L1362.39 435.088L1362.14 435.332Z" fill="#FFBA33" />
        <path d="M1475.82 378.465L1161.97 257.648L1161.84 257.974L1475.82 378.818V378.465Z" fill="#FFBA33" />
        <path d="M1475.82 371.763L1153.71 220.273L1153.57 220.572L1475.82 372.143V371.763Z" fill="#FFBA33" />
        <path d="M1475.82 426.619L1406.68 442.607L1406.76 442.96L1475.82 426.972V426.619Z" fill="#FFBA33" />
        <path d="M1475.82 371.789L1149.53 218.291L1149.37 218.59L1475.82 372.142V371.789Z" fill="#FFBA33" />
        <path d="M1120.85 203.225L1024.01 143.97L1024.2 143.672L1121.04 202.927L1120.85 203.225Z" fill="#FFBA33" />
        <path d="M1362.39 435.088L1146.22 223.014L1146.47 222.797L1362.63 434.844L1362.39 435.088Z" fill="#FFBA33" />
        <path d="M1124.13 240.387L980.341 160.91L980.503 160.611L1124.3 240.088L1124.13 240.387Z" fill="#FFBA33" />
        <path d="M1360.38 437.506L979.284 162.539L979.473 162.268L1360.57 437.234L1360.38 437.506Z" fill="#FFBA33" />
        <path d="M953.073 143.429L920.791 119.705L921.008 119.434L953.263 143.157L953.073 143.429Z" fill="#FFBA33" />
        <path d="M950.96 190.333L944.834 187.808L944.97 187.482L951.095 190.007L950.96 190.333Z" fill="#FFBA33" />
        <path d="M942.847 191.194L942.633 191.475L946.764 194.644L946.978 194.364L942.847 191.194Z" fill="#FFBA33" />
        <path d="M1359.41 438.995L943.722 189.924L943.912 189.625L1359.6 438.696L1359.41 438.995Z" fill="#FFBA33" />
        <path d="M990.262 130.75L949.712 124.779L949.767 124.453L990.316 130.398L990.262 130.75Z" fill="#FFBA33" />
        <path d="M1475.82 369.183L1020.86 140.062L1020.73 140.388L1475.82 369.563V369.183Z" fill="#FFBA33" />
        <path d="M1116.9 200.809L1020.05 141.554L1020.24 141.256L1117.09 200.511L1116.9 200.809Z" fill="#FFBA33" />
        <path d="M1361.41 436.146L1018.53 143.59L1018.78 143.318L1361.63 435.901L1361.41 436.146Z" fill="#FFBA33" />
        <path d="M990.56 129.15L924.288 112.457L924.369 112.131L990.642 128.824L990.56 129.15Z" fill="#FFBA33" />
        <path d="M819.435 9.78709L819.241 10.082L992.638 124.142L992.832 123.847L819.435 9.78709Z" fill="#FFBA33" />
        <path d="M1364.2 440.136L945.837 134.035L946.054 133.764L1364.39 439.837L1364.2 440.136Z" fill="#FFBA33" />
        <path d="M1364.91 439.213L1158.23 263.945L1158.45 263.674L1365.13 438.941L1364.91 439.213Z" fill="#FFBA33" />
        <path d="M1422.21 462.069L1392.96 454.035L1393.07 453.682L1422.32 461.743L1422.21 462.069Z" fill="#FFBA33" />
        <path d="M1363.5 441.193L987.931 209.223L988.094 208.951L1363.69 440.895L1363.5 441.193Z" fill="#FFBA33" />
        <path d="M1383.75 464.384L1383.45 464.514L1462.82 647.382L1463.12 647.252L1383.75 464.384Z" fill="#FFBA33" />
        <path d="M1475.82 597.49L1386.51 462.992L1386.21 463.182L1475.82 598.114V597.49Z" fill="#FFBA33" />
        <path d="M1364.01 440.38L1299.99 395.484L1300.18 395.213L1364.2 440.109L1364.01 440.38Z" fill="#FFBA33" />
        <path d="M1363.31 441.52L981.344 217.855L981.506 217.557L1363.47 441.221L1363.31 441.52Z" fill="#FFBA33" />
        <path d="M1475.82 400.777L1391.85 442.226L1391.99 442.552L1475.82 401.157V400.777Z" fill="#FFBA33" />
        <path d="M1475.82 558.32L1388.38 461.498L1388.14 461.715L1475.82 558.836V558.32Z" fill="#FFBA33" />
        <path d="M1365.5 438.537L1217.65 298.231L1217.86 297.986L1365.75 438.293L1365.5 438.537Z" fill="#FFBA33" />
        <path d="M1475.82 426.619L1393.15 445.755L1393.21 446.081L1475.82 426.972V426.619Z" fill="#FFBA33" />
        <path d="M1365.69 438.319L1149.53 226.272L1149.78 226.027L1365.94 438.075L1365.69 438.319Z" fill="#FFBA33" />
        <path d="M1364.15 440.219L983.025 165.252L983.242 164.98L1364.34 439.947L1364.15 440.219Z" fill="#FFBA33" />
        <path d="M1363.39 441.386L947.707 192.314L947.869 192.016L1363.58 441.087L1363.39 441.386Z" fill="#FFBA33" />
        <path d="M1364.94 439.161L1022.08 146.605L1022.3 146.334L1365.15 438.917L1364.94 439.161Z" fill="#FFBA33" />
        <path d="M1364.15 440.193L920.899 119.57L921.116 119.271L1364.34 439.921L1364.15 440.193Z" fill="#FFBA33" />
        <path d="M918.081 115.442L910.979 111.344L911.142 111.045L918.244 115.144L918.081 115.442Z" fill="#FFBA33" />
        <path d="M949.333 140.688L917.078 116.937L917.268 116.666L949.523 140.417L949.333 140.688Z" fill="#FFBA33" />
        <path d="M986.06 128.011L919.788 111.318L919.869 110.992L986.141 127.686L986.06 128.011Z" fill="#FFBA33" />
        <path d="M1360.41 437.478L917.159 116.855L917.349 116.557L1360.6 437.206L1360.41 437.478Z" fill="#FFBA33" />
        <path d="M815.556 7.24412L815.362 7.53906L988.76 121.599L988.953 121.304L815.556 7.24412Z" fill="#FFBA33" />
        <path d="M1475.82 570.619L1373.69 506.369L1373.53 506.668L1475.82 571.026V570.619Z" fill="#FFBA33" />
        <path d="M1362.6 481.883L1362.28 481.828L1368.24 444.479L1368.57 444.533L1362.6 481.883Z" fill="#FFBA33" />
        <path d="M1475.82 803.645L1427.39 828.97L1427.55 829.268L1475.82 804.025V803.645Z" fill="#FFBA33" />
        <path d="M1475.82 803.645L1431.48 826.825L1431.64 827.124L1475.82 804.025V803.645Z" fill="#FFBA33" />
        <path d="M196.272 911L93.896 881.061L93.7876 881.386L195.052 911H196.272Z" fill="#FFBA33" />
        <path d="M-67 838.986V839.366L92.9472 910.999H93.7604L-67 838.986Z" fill="#FFBA33" />
        <path d="M142.468 911L87.6614 884.426L87.5259 884.752L141.682 911H142.468Z" fill="#FFBA33" />
        <path d="M-67 828.889V829.269L40.6886 865.044L40.797 864.718L-67 828.889Z" fill="#FFBA33" />
        <path d="M-67 834.4V834.753L40.4446 865.833L40.5259 865.507L-67 834.4Z" fill="#FFBA33" />
        <path d="M-67 823.488V823.868L40.9325 864.313L41.0681 863.987L-67 823.488Z" fill="#FFBA33" />
        <path d="M-67 827.396V827.749L40.7428 864.828L40.8512 864.529L-67 827.396Z" fill="#FFBA33" />
        <path d="M-67 825.578V825.958L40.8241 864.584L40.9596 864.258L-67 825.578Z" fill="#FFBA33" />
        <path d="M-67 824.545V824.898L40.8783 864.446L41.0138 864.121L-67 824.545Z" fill="#FFBA33" />
        <path d="M173.585 911.002L84.5987 879.732L84.4902 880.058L172.555 911.002H173.585Z" fill="#FFBA33" />
        <path d="M-67 826.609V826.962L40.797 864.719L40.9054 864.393L-67 826.609Z" fill="#FFBA33" />
        <path d="M-67 827.805V828.158L40.7428 864.883L40.8512 864.557L-67 827.805Z" fill="#FFBA33" />
        <path d="M-67 838.986V839.366L92.9472 910.999H93.7875L-67 838.986Z" fill="#FFBA33" />
        <path d="M-67 819.715V820.095L41.1223 863.796L41.2578 863.498L-67 819.715Z" fill="#FFBA33" />
        <path d="M-67 824.602V824.954L40.8783 864.449L40.9867 864.123L-67 824.602Z" fill="#FFBA33" />
        <path d="M175.998 910.999L84.6533 879.594L84.5449 879.892L174.968 910.999H175.998Z" fill="#FFBA33" />
        <path d="M-67 820.559V820.939L41.068 863.907L41.2036 863.609L-67 820.559Z" fill="#FFBA33" />
        <path d="M-67 837.223V837.576L40.3362 866.239L40.4175 865.914L-67 837.223Z" fill="#FFBA33" />
        <path d="M-67 826.719V827.072L40.7699 864.747L40.9054 864.422L-67 826.719Z" fill="#FFBA33" />
        <path d="M196.272 911.001L76.1148 875.85L76.0063 876.175L195.052 911.001H196.272Z" fill="#FFBA33" />
        <path d="M142.468 911L75.1658 878.373L75.0303 878.672L141.682 911H142.468Z" fill="#FFBA33" />
        <path d="M-67 828.945V829.298L45.0796 866.512L45.188 866.186L-67 828.945Z" fill="#FFBA33" />
        <path d="M-67 828.889V829.269L45.0796 866.51L45.188 866.184L-67 828.889Z" fill="#FFBA33" />
        <path d="M-67 834.4V834.753L44.8899 867.109L44.9983 866.783L-67 834.4Z" fill="#FFBA33" />
        <path d="M-67 823.488V823.868L45.2693 865.941L45.4049 865.615L-67 823.488Z" fill="#FFBA33" />
        <path d="M-67 827.396V827.749L45.1338 866.348L45.2422 866.022L-67 827.396Z" fill="#FFBA33" />
        <path d="M-67 825.578V825.958L45.188 866.158L45.2964 865.832L-67 825.578Z" fill="#FFBA33" />
        <path d="M-67 824.545V824.898L45.2422 866.048L45.3507 865.722L-67 824.545Z" fill="#FFBA33" />
        <path d="M173.585 910.999L75.8709 876.662L75.7354 876.988L172.555 910.999H173.585Z" fill="#FFBA33" />
        <path d="M-67 826.609V826.962L45.1609 866.267L45.2693 865.941L-67 826.609Z" fill="#FFBA33" />
        <path d="M-67 827.805V828.158L45.1067 866.376L45.2151 866.077L-67 827.805Z" fill="#FFBA33" />
        <path d="M-67 819.715V820.095L45.4049 865.534L45.5404 865.235L-67 819.715Z" fill="#FFBA33" />
        <path d="M-67 824.602V824.954L45.2422 866.05L45.3507 865.724L-67 824.602Z" fill="#FFBA33" />
        <path d="M175.997 911L75.898 876.582L75.7896 876.881L174.967 911H175.997Z" fill="#FFBA33" />
        <path d="M-67 820.559V820.939L45.3778 865.617L45.5133 865.319L-67 820.559Z" fill="#FFBA33" />
        <path d="M-67 837.223V837.576L44.8086 867.434L44.8899 867.108L-67 837.223Z" fill="#FFBA33" />
        <path d="M-67 840.1V840.452L44.7272 867.786L44.8085 867.433L-67 840.1Z" fill="#FFBA33" />
        <path d="M-67 840.1V840.452L40.2278 866.673L40.3091 866.348L-67 840.1Z" fill="#FFBA33" />
        <path d="M1120.04 911.001L1016.31 887.223L1016.23 887.576L1118.52 911.001H1120.04Z" fill="#FFBA33" />
        <path d="M983.378 910.999L987.199 897.372L986.874 897.291L983.025 910.999H983.378Z" fill="#FFBA33" />
        <path d="M1120.04 910.999L1007.28 885.158L1007.2 885.484L1118.52 910.999H1120.04Z" fill="#FFBA33" />
        <path d="M993.297 910.999L992.538 897.889L992.213 897.916L992.945 910.999H993.297Z" fill="#FFBA33" />
        <path d="M993.297 911.002L992.809 902.533L992.457 902.56L992.945 911.002H993.297Z" fill="#FFBA33" />
        <path d="M485.343 445.851L485.176 446.162L565.564 489.369L565.731 489.058L485.343 445.851Z" fill="#FFBA33" />
        <path d="M671.858 541.898L498.358 447.519L498.521 447.221L672.021 541.6L671.858 541.898Z" fill="#FFBA33" />
        <path d="M1475.82 394.157L1410.47 359.766L1410.34 360.064L1475.82 394.537V394.157Z" fill="#FFBA33" />
        <path d="M1475.82 394.155L1414.59 361.908L1414.43 362.207L1475.82 394.535V394.155Z" fill="#FFBA33" />
        <path d="M1004.69 875.086L1002.82 878.576L1003.13 878.743L1005 875.253L1004.69 875.086Z" fill="#FFBA33" />
        <path d="M1123.7 910.998L1025.66 870.391L1025.52 870.689L1122.81 910.998H1123.7Z" fill="#FFBA33" />
        <path d="M1020.62 846.016L1020.32 845.88L1022.19 842.379L1022.49 842.542L1020.62 846.016Z" fill="#FFBA33" />
        <path d="M1139.07 911.001L1029.59 863.119L1029.46 863.418L1138.2 911.001H1139.07Z" fill="#FFBA33" />
        <path d="M1123.7 911.001L1029.94 872.158L1029.81 872.484L1122.81 911.001H1123.7Z" fill="#FFBA33" />
        <path d="M1139.07 911.001L1033.85 864.965L1033.71 865.263L1138.2 911.001H1139.07Z" fill="#FFBA33" />
        <path d="M371.832 911.001L227.904 797.078L227.714 797.35L371.263 911.001H371.832Z" fill="#FFBA33" />
        <path d="M192.314 911L-64.7778 486.199L-65.0488 486.362L191.935 911H192.314Z" fill="#FFBA33" />
        <path d="M201.367 766.026L201.069 766.188L154.177 682.993L154.475 682.803L201.367 766.026Z" fill="#FFBA33" />
        <path d="M-67 249.561V250.321L138.836 654.383L139.161 654.22L-67 249.561Z" fill="#FFBA33" />
        <path d="M192.315 910.999L-62.3649 490.162L-62.6631 490.325L191.935 910.999H192.315Z" fill="#FFBA33" />
        <path d="M-67 544.965V545.426L195.54 770.828L195.757 770.584L-67 544.965Z" fill="#FFBA33" />
        <path d="M456.345 910.999L230.045 793.711L229.882 794.037L455.587 910.999H456.345Z" fill="#FFBA33" />
        <path d="M371.831 910.999L224.271 794.227L224.082 794.471L371.262 910.999H371.831Z" fill="#FFBA33" />
        <path d="M203.644 770.071L203.346 770.233L156.454 687.011L156.752 686.848L203.644 770.071Z" fill="#FFBA33" />
        <path d="M-67 544.965V545.426L199.037 773.841L199.253 773.597L-67 544.965Z" fill="#FFBA33" />
        <path d="M456.345 911.001L225.924 791.596L225.762 791.894L455.586 911.001H456.345Z" fill="#FFBA33" />
        <path d="M-67 249.561V250.321L136.749 650.257L137.047 650.094L-67 249.561Z" fill="#FFBA33" />
        <path d="M415.66 911.001L161.984 682.586L161.74 682.83L415.145 911.001H415.66Z" fill="#FFBA33" />
        <path d="M415.661 911L158.542 679.49L158.298 679.735L415.146 911H415.661Z" fill="#FFBA33" />
        <path d="M347.356 911L156.834 681.146L156.59 681.364L346.922 911H347.356Z" fill="#FFBA33" />
        <path d="M347.356 910.999L159.816 684.701L159.545 684.918L346.923 910.999H347.356Z" fill="#FFBA33" />
        <path d="M793.478 911L840.397 750.445L840.045 750.363L793.126 911H793.478Z" fill="#FFBA33" />
        <path d="M793.478 911.001L841.671 745.994L841.346 745.912L793.126 911.001H793.478Z" fill="#FFBA33" />
        <path d="M1475.82 407.455L1392.23 443.068L1392.37 443.366L1475.82 407.835V407.455Z" fill="#FFBA33" />
        <path d="M1475.82 762.034L1408.47 733.533L1408.33 733.832L1475.82 762.387V762.034Z" fill="#FFBA33" />
        <path d="M1475.82 762.036L1412.75 735.326L1412.61 735.652L1475.82 762.389V762.036Z" fill="#FFBA33" />
        <path d="M1475.82 765.888L1412.37 740.373L1412.23 740.672L1475.82 766.241V765.888Z" fill="#FFBA33" />
        <path d="M1475.82 765.889L1408.06 738.637L1407.95 738.962L1475.82 766.242V765.889Z" fill="#FFBA33" />
        <path d="M1475.82 840.155L1436.76 818.576L1436.6 818.875L1475.82 840.536V840.155Z" fill="#FFBA33" />
        <path d="M1475.82 817.112L1438.61 812.66L1438.55 812.986L1475.82 817.465V817.112Z" fill="#FFBA33" />
        <path d="M1154.52 911.001L879.618 794.201L879.51 794.5L1153.65 911.001H1154.52Z" fill="#FFBA33" />
        <path d="M841.237 777.94L636.594 687.877L636.729 687.578L841.373 777.641L841.237 777.94Z" fill="#FFBA33" />
        <path d="M1154.52 911.001L875.363 792.383L875.228 792.681L1153.65 911.001H1154.52Z" fill="#FFBA33" />
        <path d="M845.467 779.813L640.823 689.75L640.959 689.424L845.602 779.514L845.467 779.813Z" fill="#FFBA33" />
        <path d="M1475.82 380.746L1217.94 289.027L1217.84 289.353L1475.82 381.099V380.746Z" fill="#FFBA33" />
        <path d="M1475.82 380.748L1222.31 290.576L1222.2 290.902L1475.82 381.101V380.748Z" fill="#FFBA33" />
        <path d="M1475.82 369.183L1025.01 142.152L1024.85 142.451L1475.82 369.563V369.183Z" fill="#FFBA33" />
        <path d="M826.818 722.404L349.28 520.373L349.415 520.074L826.953 722.105L826.818 722.404Z" fill="#FFBA33" />
        <path d="M319.218 508.174L319.107 508.48L330.616 512.686L330.728 512.38L319.218 508.174Z" fill="#FFBA33" />
        <path d="M-67 331.453V331.806L826.899 722.188L827.035 721.889L-67 331.453Z" fill="#FFBA33" />
        <path d="M415.824 507.264L415.525 507.101L417.314 504.088L417.612 504.251L415.824 507.264Z" fill="#FFBA33" />
        <path d="M181.906 432.837L175.916 430.394L176.024 430.068L182.042 432.538L181.906 432.837Z" fill="#FFBA33" />
        <path d="M181.876 432.889L181.735 433.213L215.023 447.762L215.164 447.439L181.876 432.889Z" fill="#FFBA33" />
        <path d="M192.721 437.097L181.934 432.754L182.069 432.455L192.857 436.798L192.721 437.097Z" fill="#FFBA33" />
        <path d="M181.83 433.02L181.687 433.342L826.963 722.043L827.107 721.721L181.83 433.02Z" fill="#FFBA33" />
        <path d="M152.163 419.953L152.033 420.252L165.389 426.047L165.519 425.749L152.163 419.953Z" fill="#FFBA33" />
        <path d="M181.625 433.477L181.471 433.795L243.071 463.724L243.225 463.406L181.625 433.477Z" fill="#FFBA33" />
        <path d="M280.325 480.229L181.554 433.623L181.689 433.324L280.46 479.93L280.325 480.229Z" fill="#FFBA33" />
        <path d="M826.818 722.406L382.918 534.218L383.053 533.893L826.954 722.08L826.818 722.406Z" fill="#FFBA33" />
        <path d="M353.59 520.4L280.325 482.426L280.487 482.127L353.752 520.101L353.59 520.4Z" fill="#FFBA33" />
        <path d="M390.078 501.313L389.92 501.629L827.38 721.21L827.537 720.894L390.078 501.313Z" fill="#FFBA33" />
        <path d="M352.111 486.611L352.005 486.947L355.57 488.079L355.677 487.743L352.111 486.611Z" fill="#FFBA33" />
        <path d="M350.148 491.438L349.85 491.248L350.039 490.949L350.338 491.166L350.148 491.438Z" fill="#FFBA33" />
        <path d="M186.189 434.599L180.199 432.156L180.334 431.83L186.324 434.273L186.189 434.599Z" fill="#FFBA33" />
        <path d="M192.667 437.286L180.117 432.345L180.226 432.047L192.775 436.96L192.667 437.286Z" fill="#FFBA33" />
        <path d="M826.98 721.999L209.987 444.916L210.122 444.59L827.116 721.701L826.98 721.999Z" fill="#FFBA33" />
        <path d="M204.206 441.216L204.095 441.521L210.434 443.841L210.546 443.535L204.206 441.216Z" fill="#FFBA33" />
        <path d="M216.547 412.505L56.3555 328.142L56.5181 327.844L216.709 412.18L216.547 412.505Z" fill="#FFBA33" />
        <path d="M419.761 531.805L419.612 532.125L827.106 721.807L827.255 721.487L419.761 531.805Z" fill="#FFBA33" />
        <path d="M827.387 721.131L249.479 429.498L249.615 429.199L827.549 720.832L827.387 721.131Z" fill="#FFBA33" />
        <path d="M186.118 434.733L185.977 435.057L219.265 449.606L219.406 449.282L186.118 434.733Z" fill="#FFBA33" />
        <path d="M826.981 722L248.91 462.75L249.073 462.424L827.116 721.701L826.981 722Z" fill="#FFBA33" />
        <path d="M203.722 442.386L203.581 442.709L219.274 449.586L219.415 449.263L203.722 442.386Z" fill="#FFBA33" />
        <path d="M827.17 721.59L381.942 510.302L382.077 509.977L827.306 721.291L827.17 721.59Z" fill="#FFBA33" />
        <path d="M197.031 438.835L186.243 434.492L186.352 434.166L197.139 438.509L197.031 438.835Z" fill="#FFBA33" />
        <path d="M227.144 450.778L214.595 445.865L214.73 445.566L227.28 450.48L227.144 450.778Z" fill="#FFBA33" />
        <path d="M827.008 721.972L226.847 451.539L226.982 451.213L827.143 721.674L827.008 721.972Z" fill="#FFBA33" />
        <path d="M203.969 441.792L196.922 439.105L197.03 438.779L204.105 441.467L203.969 441.792Z" fill="#FFBA33" />
        <path d="M827.008 721.971L388.881 524.201L389.016 523.902L827.143 721.645L827.008 721.971Z" fill="#FFBA33" />
        <path d="M281.312 480.229L281.182 480.557L359.032 511.558L359.162 511.23L281.312 480.229Z" fill="#FFBA33" />
        <path d="M-67 342.908V343.288L826.818 722.406L826.953 722.08L-67 342.908Z" fill="#FFBA33" />
        <path d="M831.073 724.224L353.536 522.193L353.671 521.867L831.209 723.898L831.073 724.224Z" fill="#FFBA33" />
        <path d="M-67 331.453V331.806L831.155 724.061L831.29 723.735L-67 331.453Z" fill="#FFBA33" />
        <path d="M427.976 527.05L427.822 527.367L831.418 723.456L831.572 723.139L427.976 527.05Z" fill="#FFBA33" />
        <path d="M186.067 434.914L185.923 435.236L831.2 723.938L831.344 723.615L186.067 434.914Z" fill="#FFBA33" />
        <path d="M831.073 724.197L387.173 536.01L387.308 535.711L831.209 723.899L831.073 724.197Z" fill="#FFBA33" />
        <path d="M831.426 723.437L355.271 491.14L355.433 490.842L831.588 723.111L831.426 723.437Z" fill="#FFBA33" />
        <path d="M831.209 723.898L214.216 446.814L214.351 446.488L831.345 723.572L831.209 723.898Z" fill="#FFBA33" />
        <path d="M831.209 723.898L253.138 464.621L253.274 464.322L831.344 723.6L831.209 723.898Z" fill="#FFBA33" />
        <path d="M831.236 723.873L231.047 453.439L231.21 453.113L831.371 723.574L831.236 723.873Z" fill="#FFBA33" />
        <path d="M831.236 723.871L393.109 526.128L393.244 525.803L831.371 723.545L831.236 723.871Z" fill="#FFBA33" />
        <path d="M-67 342.908V343.288L831.073 724.197L831.209 723.899L-67 342.908Z" fill="#FFBA33" />
        <path d="M831.209 723.925L203.536 442.878L203.672 442.553L831.345 723.6L831.209 723.925Z" fill="#FFBA33" />
        <path d="M831.182 724.004L280.894 481.257L281.03 480.932L831.317 723.679L831.182 724.004Z" fill="#FFBA33" />
        <path d="M249.703 447.087L249.552 447.406L831.314 723.551L831.465 723.232L249.703 447.087Z" fill="#FFBA33" />
        <path d="M831.236 723.844L470.792 560.601L470.928 560.275L831.372 723.545L831.236 723.844Z" fill="#FFBA33" />
        <path d="M831.127 724.114L441.627 556.175L441.762 555.85L831.263 723.815L831.127 724.114Z" fill="#FFBA33" />
        <path d="M466.478 565.334L466.349 565.633L831.086 724.055L831.216 723.756L466.478 565.334Z" fill="#FFBA33" />
        <path d="M831.182 723.978L318.245 497.545L318.38 497.219L831.317 723.679L831.182 723.978Z" fill="#FFBA33" />
        <path d="M830.884 724.684L268.29 506.801L268.426 506.502L831.019 724.358L830.884 724.684Z" fill="#FFBA33" />
        <path d="M831.263 723.764L444.988 546.216L445.151 545.891L831.426 723.465L831.263 723.764Z" fill="#FFBA33" />
        <path d="M831.074 724.251L330.253 513.642L330.388 513.316L831.182 723.925L831.074 724.251Z" fill="#FFBA33" />
        <path d="M186.169 434.721L186.04 435.02L199.396 440.815L199.525 440.516L186.169 434.721Z" fill="#FFBA33" />
        <path d="M169.418 428.458L169.307 428.764L175.646 431.083L175.758 430.777L169.418 428.458Z" fill="#FFBA33" />
        <path d="M199.489 440.567L199.348 440.891L215.041 447.768L215.183 447.445L199.489 440.567Z" fill="#FFBA33" />
        <path d="M826.981 722.025L199.308 440.978L199.443 440.68L827.116 721.699L826.981 722.025Z" fill="#FFBA33" />
        <path d="M349.47 518.282L276.205 480.281L276.368 479.982L349.633 517.984L349.47 518.282Z" fill="#FFBA33" />
        <path d="M277.013 478.533L276.883 478.861L354.733 509.863L354.863 509.535L277.013 478.533Z" fill="#FFBA33" />
        <path d="M276.789 479.079L276.647 479.402L826.896 722.132L827.038 721.809L276.789 479.079Z" fill="#FFBA33" />
        <path d="M280.081 480.797L276.693 479.304L276.828 479.006L280.217 480.472L280.081 480.797Z" fill="#FFBA33" />
        <path d="M293.661 493.992L275.311 481.804L275.5 481.506L293.85 493.721L293.661 493.992Z" fill="#FFBA33" />
        <path d="M59.8647 331.308L59.6782 331.607L216.966 429.846L217.153 429.547L59.8647 331.308Z" fill="#FFBA33" />
        <path d="M827.008 721.943L466.564 558.674L466.727 558.375L827.144 721.618L827.008 721.943Z" fill="#FFBA33" />
        <path d="M435.856 549.416L435.811 549.738L443.167 550.762L443.212 550.439L435.856 549.416Z" fill="#FFBA33" />
        <path d="M826.872 722.296L437.372 554.33L437.508 554.031L827.008 721.97L826.872 722.296Z" fill="#FFBA33" />
        <path d="M438.564 550.15L431.219 549.146L431.273 548.793L438.618 549.825L438.564 550.15Z" fill="#FFBA33" />
        <path d="M462.227 563.494L462.097 563.793L826.835 722.215L826.964 721.916L462.227 563.494Z" fill="#FFBA33" />
        <path d="M441.248 551.725L435.366 546.215L435.61 545.971L441.465 551.481L441.248 551.725Z" fill="#FFBA33" />
        <path d="M826.926 722.107L313.989 495.673L314.152 495.348L827.062 721.808L826.926 722.107Z" fill="#FFBA33" />
        <path d="M284.309 482.643L280.921 481.177L281.056 480.852L284.445 482.317L284.309 482.643Z" fill="#FFBA33" />
        <path d="M56.3785 420.693L56.2461 421.02L229.562 491.423L229.695 491.096L56.3785 420.693Z" fill="#FFBA33" />
        <path d="M264.929 501.738L264.869 502.086L313.854 510.559L313.914 510.211L264.929 501.738Z" fill="#FFBA33" />
        <path d="M826.574 723.002L263.98 505.146L264.089 504.82L826.71 722.704L826.574 723.002Z" fill="#FFBA33" />
        <path d="M233.839 493.174L60.5293 422.763L60.6648 422.465L233.975 492.876L233.839 493.174Z" fill="#FFBA33" />
        <path d="M827.062 721.836L440.787 544.289L440.923 543.963L827.197 721.51L827.062 721.836Z" fill="#FFBA33" />
        <path d="M431.262 521.979L429.181 528.008L429.489 528.114L431.57 522.086L431.262 521.979Z" fill="#FFBA33" />
        <path d="M437.86 548.55L431.978 543.067L432.222 542.822L438.104 548.305L437.86 548.55Z" fill="#FFBA33" />
        <path d="M314.889 506.633L314.777 506.939L326.286 511.145L326.398 510.839L314.889 506.633Z" fill="#FFBA33" />
        <path d="M826.791 722.46L325.997 511.851L326.133 511.525L826.926 722.134L826.791 722.46Z" fill="#FFBA33" />
        <path d="M297.51 496.566L279.16 484.352L279.349 484.08L297.7 496.268L297.51 496.566Z" fill="#FFBA33" />
        <path d="M1475.82 376.459L1407.73 351.486L1407.6 351.812L1475.82 376.812V376.459Z" fill="#FFBA33" />
        <path d="M1475.82 354.525L1378 301.242L1377.84 301.541L1475.82 354.933V354.525Z" fill="#FFBA33" />
        <path d="M1475.82 370.077L1389.28 333.867L1389.14 334.166L1475.82 370.43V370.077Z" fill="#FFBA33" />
        <path d="M1475.82 384.518L1420.64 367.906L1420.56 368.259L1475.82 384.871V384.518Z" fill="#FFBA33" />
        <path d="M1475.82 394.4L1430.07 384.303L1430.02 384.628L1475.82 394.753V394.4Z" fill="#FFBA33" />
        <path d="M1475.82 376.457L1403.37 349.91L1403.26 350.209L1475.82 376.81V376.457Z" fill="#FFBA33" />
        <path d="M1475.82 354.527L1373.93 299.018L1373.77 299.316L1475.82 354.934V354.527Z" fill="#FFBA33" />
        <path d="M1475.82 370.079L1384.99 332.078L1384.86 332.377L1475.82 370.432V370.079Z" fill="#FFBA33" />
        <path d="M1475.82 384.518L1416.22 366.576L1416.11 366.902L1475.82 384.871V384.518Z" fill="#FFBA33" />
        <path d="M1475.82 394.397L1425.57 383.322L1425.49 383.648L1475.82 394.75V394.397Z" fill="#FFBA33" />
        <path d="M892.981 0L1378.89 340.546L1379.08 340.247L893.551 0H892.981Z" fill="#FFBA33" />
        <path d="M899.297 0L1378.95 340.437L1379.17 340.166L899.866 0H899.297Z" fill="#FFBA33" />
        <path d="M892.981 0L1382.69 343.206L1382.88 342.908L893.551 0H892.981Z" fill="#FFBA33" />
        <path d="M895.096 0L1382.69 343.179L1382.91 342.88L895.692 0H895.096Z" fill="#FFBA33" />
        <path d="M1219.25 296.304L1219.04 296.584L1429.33 457.7L1429.55 457.42L1219.25 296.304Z" fill="#FFBA33" />
        <path d="M1215.56 293.483L1215.35 293.764L1425.64 454.88L1425.86 454.599L1215.56 293.483Z" fill="#FFBA33" />
        <path
          d="M795.837 586.658C796.651 585.843 797.979 585.843 798.792 586.658C799.605 587.472 799.605 588.802 798.792 589.616C797.979 590.431 796.651 590.431 795.837 589.616C795.024 588.802 795.024 587.472 795.837 586.658Z"
          fill="#FFA800"
        />
        <path
          d="M1076.27 719.039C1076.46 718.849 1076.73 718.849 1076.92 719.039C1077.11 719.229 1077.11 719.501 1076.92 719.691C1076.73 719.881 1076.46 719.881 1076.27 719.691C1076.08 719.528 1076.08 719.229 1076.27 719.039Z"
          fill="#FFA800"
        />
        <path
          d="M1435.9 563.83C1437.36 562.364 1439.75 562.364 1441.21 563.83C1442.67 565.296 1442.67 567.684 1441.21 569.15C1439.75 570.616 1437.36 570.616 1435.9 569.15C1434.43 567.684 1434.43 565.296 1435.9 563.83Z"
          fill="#FFA800"
        />
        <path
          d="M1359.89 499.336C1360.7 499.336 1361.36 498.68 1361.36 497.87C1361.36 497.061 1360.7 496.404 1359.89 496.404C1359.09 496.404 1358.43 497.061 1358.43 497.87C1358.43 498.68 1359.09 499.336 1359.89 499.336Z"
          fill="#FFA800"
        />
        <path
          d="M1369.6 421.813C1370.76 420.646 1372.63 420.646 1373.8 421.813C1374.96 422.98 1374.96 424.853 1373.8 426.02C1372.63 427.187 1370.76 427.187 1369.6 426.02C1368.43 424.853 1368.43 422.98 1369.6 421.813Z"
          fill="#FFA800"
        />
        <path
          d="M844.6 728.917C845.413 728.103 846.741 728.103 847.527 728.917C848.34 729.732 848.34 731.062 847.527 731.849C846.714 732.663 845.386 732.663 844.6 731.849C843.786 731.035 843.786 729.732 844.6 728.917Z"
          fill="#FFA800"
        />
        <path
          d="M1375.37 447.546C1376.48 446.433 1378.27 446.433 1379.38 447.546C1380.49 448.659 1380.49 450.45 1379.38 451.563C1378.27 452.676 1376.48 452.676 1375.37 451.563C1374.26 450.45 1374.26 448.659 1375.37 447.546Z"
          fill="#FFA800"
        />
        <path
          d="M555.605 491.41C556.847 491.41 557.854 490.402 557.854 489.157C557.854 487.913 556.847 486.904 555.605 486.904C554.362 486.904 553.355 487.913 553.355 489.157C553.355 490.402 554.362 491.41 555.605 491.41Z"
          fill="#FFA800"
        />
        <path
          d="M689.748 551.209C690.019 550.938 690.453 550.938 690.724 551.209C690.995 551.481 690.995 551.915 690.724 552.187C690.453 552.458 690.019 552.458 689.748 552.187C689.477 551.915 689.477 551.481 689.748 551.209Z"
          fill="#FFA800"
        />
        <path
          d="M486.541 452.595C487.544 451.591 489.143 451.591 490.146 452.595C491.149 453.599 491.149 455.201 490.146 456.205C489.143 457.21 487.544 457.21 486.541 456.205C485.538 455.228 485.538 453.599 486.541 452.595Z"
          fill="#FFA800"
        />
        <path
          d="M491.556 463.751C491.825 463.751 492.044 463.532 492.044 463.262C492.044 462.992 491.825 462.773 491.556 462.773C491.286 462.773 491.068 462.992 491.068 463.262C491.068 463.532 491.286 463.751 491.556 463.751Z"
          fill="#FFA800"
        />
        <path
          d="M582.141 497.218C583.144 496.214 584.797 496.214 585.827 497.218C586.83 498.222 586.83 499.878 585.827 500.91C584.824 501.914 583.171 501.914 582.141 500.91C581.138 499.878 581.138 498.222 582.141 497.218Z"
          fill="#FFA800"
        />
        <path
          d="M628.626 523.44C630.736 523.44 632.447 521.726 632.447 519.612C632.447 517.499 630.736 515.785 628.626 515.785C626.515 515.785 624.804 517.499 624.804 519.612C624.804 521.726 626.515 523.44 628.626 523.44Z"
          fill="#FFA800"
        />
        <path
          d="M1310.64 729.27C1311.48 728.428 1312.81 728.428 1313.65 729.27C1314.49 730.111 1314.49 731.441 1313.65 732.283C1312.81 733.124 1311.48 733.124 1310.64 732.283C1309.8 731.441 1309.8 730.084 1310.64 729.27Z"
          fill="#FFA800"
        />
        <path
          d="M1221.9 681.499C1222.96 680.44 1224.69 680.44 1225.75 681.499C1226.81 682.558 1226.81 684.295 1225.75 685.353C1224.69 686.412 1222.96 686.412 1221.9 685.353C1220.85 684.295 1220.85 682.558 1221.9 681.499Z"
          fill="#FFA800"
        />
        <path
          d="M986.224 580.034C986.712 579.546 987.471 579.546 987.959 580.034C988.446 580.523 988.446 581.283 987.959 581.772C987.471 582.26 986.712 582.26 986.224 581.772C985.736 581.31 985.736 580.523 986.224 580.034Z"
          fill="#FFA800"
        />
        <path
          d="M470.955 440.788C472.318 440.788 473.422 439.682 473.422 438.318C473.422 436.954 472.318 435.848 470.955 435.848C469.593 435.848 468.489 436.954 468.489 438.318C468.489 439.682 469.593 440.788 470.955 440.788Z"
          fill="#FFA800"
        />
        <path
          d="M484.183 440.705C484.767 440.705 485.24 440.231 485.24 439.646C485.24 439.062 484.767 438.588 484.183 438.588C483.599 438.588 483.126 439.062 483.126 439.646C483.126 440.231 483.599 440.705 484.183 440.705Z"
          fill="#FFA800"
        />
        <path
          d="M60.0148 870.961C60.2859 870.69 60.7195 870.69 60.9906 870.961C61.2616 871.233 61.2616 871.667 60.9906 871.939C60.7195 872.21 60.2859 872.21 60.0148 871.939C59.7438 871.667 59.7438 871.233 60.0148 870.961Z"
          fill="#FFA800"
        />
        <path
          d="M658.008 568.986C659.038 567.955 660.719 567.955 661.776 568.986C662.806 570.018 662.806 571.701 661.776 572.759C660.746 573.791 659.065 573.791 658.008 572.759C656.951 571.701 656.951 570.018 658.008 568.986Z"
          fill="#FFA800"
        />
        <path
          d="M660.474 588.666C660.935 588.205 661.667 588.205 662.101 588.666C662.561 589.128 662.561 589.861 662.101 590.295C661.64 590.757 660.935 590.757 660.474 590.295C660.014 589.861 660.014 589.128 660.474 588.666Z"
          fill="#FFA800"
        />
        <path
          d="M1106.65 868.274C1107.25 867.677 1108.2 867.677 1108.79 868.274C1109.39 868.871 1109.39 869.821 1108.79 870.418C1108.2 871.016 1107.25 871.016 1106.65 870.418C1106.08 869.821 1106.08 868.871 1106.65 868.274Z"
          fill="#FFA800"
        />
        <path
          d="M801.041 787.712C801.34 787.414 801.8 787.414 802.099 787.712C802.397 788.011 802.397 788.472 802.099 788.771C801.8 789.069 801.34 789.069 801.041 788.771C800.743 788.499 800.743 788.011 801.041 787.712Z"
          fill="#FFA800"
        />
        <path
          d="M704.493 752.613C705.376 752.613 706.092 751.896 706.092 751.012C706.092 750.127 705.376 749.41 704.493 749.41C703.61 749.41 702.894 750.127 702.894 751.012C702.894 751.896 703.61 752.613 704.493 752.613Z"
          fill="#FFA800"
        />
        <path
          d="M989.042 879.294C990.371 877.964 992.539 877.964 993.867 879.294C995.195 880.624 995.195 882.796 993.867 884.126C992.539 885.456 990.371 885.456 989.042 884.126C987.714 882.796 987.714 880.624 989.042 879.294Z"
          fill="#FFA800"
        />
        <path
          d="M741.817 773.027C743.748 773.027 745.313 771.459 745.313 769.525C745.313 767.591 743.748 766.023 741.817 766.023C739.886 766.023 738.32 767.591 738.32 769.525C738.32 771.459 739.886 773.027 741.817 773.027Z"
          fill="#FFA800"
        />
        <path
          d="M742.739 750.09C744.094 748.733 746.262 748.733 747.618 750.09C748.973 751.447 748.973 753.619 747.618 754.976C746.262 756.333 744.094 756.333 742.739 754.976C741.383 753.619 741.383 751.42 742.739 750.09Z"
          fill="#FFA800"
        />
        <path
          d="M738.89 766.024C739.622 765.291 740.787 765.291 741.492 766.024C742.224 766.757 742.224 767.924 741.492 768.63C740.76 769.336 739.594 769.363 738.89 768.63C738.158 767.924 738.158 766.757 738.89 766.024Z"
          fill="#FFA800"
        />
        <path
          d="M656.3 67.9126C657.276 66.9354 658.875 66.9354 659.851 67.9126C660.827 68.8897 660.827 70.4912 659.851 71.4684C658.875 72.4456 657.276 72.4456 656.3 71.4684C655.324 70.4912 655.324 68.8897 656.3 67.9126Z"
          fill="#FFA800"
        />
        <path
          d="M905.857 263.945C906.441 263.945 906.914 263.471 906.914 262.887C906.914 262.302 906.441 261.828 905.857 261.828C905.273 261.828 904.8 262.302 904.8 262.887C904.8 263.471 905.273 263.945 905.857 263.945Z"
          fill="#FFA800"
        />
        <path
          d="M1318.02 238.756C1318.99 237.779 1320.54 237.779 1321.51 238.756C1322.49 239.733 1322.49 241.281 1321.51 242.258C1320.54 243.235 1318.99 243.235 1318.02 242.258C1317.04 241.281 1317.04 239.733 1318.02 238.756Z"
          fill="#FFA800"
        />
        <path
          d="M928.218 120.682C928.733 120.166 929.546 120.166 930.061 120.682C930.576 121.197 930.576 122.012 930.061 122.528C929.546 123.043 928.733 123.043 928.218 122.528C927.703 122.012 927.703 121.17 928.218 120.682Z"
          fill="#FFA800"
        />
        <path
          d="M1005.25 131.862C1005.85 131.265 1006.8 131.265 1007.39 131.862C1007.99 132.459 1007.99 133.409 1007.39 134.006C1006.8 134.603 1005.85 134.603 1005.25 134.006C1004.66 133.409 1004.66 132.459 1005.25 131.862Z"
          fill="#FFA800"
        />
        <path
          d="M901.682 104.746C903.038 103.389 905.206 103.389 906.561 104.746C907.917 106.104 907.917 108.275 906.561 109.632C905.206 110.989 903.038 110.989 901.682 109.632C900.327 108.302 900.327 106.104 901.682 104.746Z"
          fill="#FFA800"
        />
        <path
          d="M1141.43 249.289C1142 248.719 1142.89 248.719 1143.46 249.289C1144.03 249.859 1144.03 250.755 1143.46 251.325C1142.89 251.895 1142 251.895 1141.43 251.325C1140.89 250.782 1140.89 249.859 1141.43 249.289Z"
          fill="#FFA800"
        />
        <path
          d="M1202.63 287.342C1204.61 287.342 1206.21 285.738 1206.21 283.759C1206.21 281.78 1204.61 280.176 1202.63 280.176C1200.66 280.176 1199.05 281.78 1199.05 283.759C1199.05 285.738 1200.66 287.342 1202.63 287.342Z"
          fill="#FFA800"
        />
        <path
          d="M964.946 151.625C965.651 150.919 966.816 150.919 967.521 151.625C968.226 152.331 968.226 153.498 967.521 154.204C966.816 154.909 965.651 154.909 964.946 154.204C964.214 153.498 964.214 152.331 964.946 151.625Z"
          fill="#FFA800"
        />
        <path
          d="M1280.34 380.718C1281.8 379.253 1284.19 379.253 1285.65 380.718C1287.12 382.184 1287.12 384.573 1285.65 386.039C1284.19 387.504 1281.8 387.504 1280.34 386.039C1278.88 384.573 1278.88 382.184 1280.34 380.718Z"
          fill="#FFA800"
        />
        <path
          d="M967.684 195.515C969.12 194.076 971.451 194.076 972.888 195.515C974.325 196.953 974.325 199.287 972.888 200.726C971.451 202.165 969.12 202.165 967.684 200.726C966.247 199.287 966.247 196.953 967.684 195.515Z"
          fill="#FFA800"
        />
        <path
          d="M928.246 179.772C929.167 178.849 930.658 178.849 931.58 179.772C932.501 180.695 932.501 182.188 931.58 183.111C930.658 184.034 929.167 184.034 928.246 183.111C927.324 182.188 927.324 180.695 928.246 179.772Z"
          fill="#FFA800"
        />
        <path
          d="M963.429 210.742C965.405 210.742 967.006 209.138 967.006 207.159C967.006 205.18 965.405 203.576 963.429 203.576C961.452 203.576 959.851 205.18 959.851 207.159C959.851 209.138 961.452 210.742 963.429 210.742Z"
          fill="#FFA800"
        />
        <path
          d="M1134.79 214.082C1136.18 214.082 1137.31 212.952 1137.31 211.558C1137.31 210.163 1136.18 209.033 1134.79 209.033C1133.39 209.033 1132.27 210.163 1132.27 211.558C1132.27 212.952 1133.39 214.082 1134.79 214.082Z"
          fill="#FFA800"
        />
        <path
          d="M1411.18 834.696C1412.23 833.638 1413.94 833.638 1415 834.696C1416.06 835.755 1416.06 837.465 1415 838.524C1413.94 839.582 1412.23 839.582 1411.18 838.524C1410.12 837.465 1410.12 835.755 1411.18 834.696Z"
          fill="#FFA800"
        />
        <path
          d="M1009.7 863.416C1010.21 862.9 1011.03 862.9 1011.51 863.416C1012.03 863.932 1012.03 864.746 1011.51 865.235C1011 865.751 1010.18 865.751 1009.7 865.235C1009.21 864.746 1009.21 863.932 1009.7 863.416Z"
          fill="#FFA800"
        />
        <path
          d="M1012.87 854.947C1013.87 853.942 1015.5 853.942 1016.5 854.947C1017.5 855.951 1017.5 857.58 1016.5 858.584C1015.5 859.588 1013.87 859.588 1012.87 858.584C1011.84 857.58 1011.84 855.951 1012.87 854.947Z"
          fill="#FFA800"
        />
        <path
          d="M210.421 783.232C210.99 782.662 211.939 782.662 212.508 783.232C213.077 783.802 213.077 784.752 212.508 785.322C211.939 785.892 210.99 785.892 210.421 785.322C209.852 784.752 209.852 783.829 210.421 783.232Z"
          fill="#FFA800"
        />
        <path
          d="M145.992 668.389C146.182 668.199 146.507 668.199 146.724 668.389C146.914 668.579 146.914 668.904 146.724 669.121C146.534 669.311 146.209 669.311 145.992 669.121C145.803 668.931 145.803 668.606 145.992 668.389Z"
          fill="#FFA800"
        />
        <path
          d="M1439.15 802.396C1439.85 801.691 1441.02 801.691 1441.72 802.396C1442.43 803.102 1442.43 804.269 1441.72 804.975C1441.02 805.681 1439.85 805.681 1439.15 804.975C1438.42 804.269 1438.42 803.102 1439.15 802.396Z"
          fill="#FFA800"
        />
        <path
          d="M1430.5 751.449C1431.32 750.634 1432.67 750.634 1433.49 751.449C1434.3 752.263 1434.3 753.62 1433.49 754.435C1432.67 755.249 1431.32 755.249 1430.5 754.435C1429.69 753.62 1429.69 752.29 1430.5 751.449Z"
          fill="#FFA800"
        />
        <path
          d="M1421.5 809.915C1422.05 809.372 1422.91 809.372 1423.46 809.915C1424 810.458 1424 811.326 1423.46 811.869C1422.91 812.412 1422.05 812.412 1421.5 811.869C1420.99 811.326 1420.99 810.458 1421.5 809.915Z"
          fill="#FFA800"
        />
        <path
          d="M1393.48 731.009C1395.48 731.009 1397.11 729.38 1397.11 727.372C1397.11 725.363 1395.48 723.734 1393.48 723.734C1391.47 723.734 1389.85 725.363 1389.85 727.372C1389.85 729.38 1391.47 731.009 1393.48 731.009Z"
          fill="#FFA800"
        />
        <path
          d="M1392.37 732.149C1392.69 731.823 1393.21 731.823 1393.53 732.149C1393.86 732.474 1393.86 732.99 1393.53 733.316C1393.21 733.641 1392.69 733.641 1392.37 733.316C1392.07 733.017 1392.07 732.474 1392.37 732.149Z"
          fill="#FFA800"
        />
        <path
          d="M860.374 788.445C861.617 788.445 862.624 787.437 862.624 786.192C862.624 784.948 861.617 783.939 860.374 783.939C859.132 783.939 858.125 784.948 858.125 786.192C858.125 787.437 859.132 788.445 860.374 788.445Z"
          fill="#FFA800"
        />
        <path
          d="M620.738 680.114C621.335 679.517 622.31 679.517 622.907 680.114C623.503 680.711 623.503 681.688 622.907 682.285C622.31 682.883 621.335 682.883 620.738 682.285C620.142 681.688 620.142 680.711 620.738 680.114Z"
          fill="#FFA800"
        />
        <path
          d="M332.096 511.605C333.37 510.329 335.43 510.329 336.704 511.605C337.978 512.881 337.978 514.944 336.704 516.22C335.43 517.495 333.37 517.495 332.096 516.22C330.849 514.944 330.849 512.881 332.096 511.605Z"
          fill="#FFA800"
        />
        <path
          d="M311.117 505.987C311.446 505.987 311.713 505.72 311.713 505.39C311.713 505.06 311.446 504.793 311.117 504.793C310.787 504.793 310.521 505.06 310.521 505.39C310.521 505.72 310.787 505.987 311.117 505.987Z"
          fill="#FFA800"
        />
        <path
          d="M409.156 519.368C409.859 519.368 410.43 518.797 410.43 518.092C410.43 517.388 409.859 516.816 409.156 516.816C408.452 516.816 407.882 517.388 407.882 518.092C407.882 518.797 408.452 519.368 409.156 519.368Z"
          fill="#FFA800"
        />
        <path
          d="M404.982 526.372C405.686 526.372 406.256 525.801 406.256 525.096C406.256 524.391 405.686 523.82 404.982 523.82C404.278 523.82 403.708 524.391 403.708 525.096C403.708 525.801 404.278 526.372 404.982 526.372Z"
          fill="#FFA800"
        />
        <path
          d="M164.966 424.553C166.078 423.441 167.867 423.441 168.951 424.553C170.062 425.666 170.062 427.458 168.951 428.544C167.839 429.656 166.05 429.656 164.966 428.544C163.855 427.458 163.855 425.666 164.966 424.553Z"
          fill="#FFA800"
        />
        <path
          d="M195.27 440.407C196.542 440.407 197.574 439.374 197.574 438.1C197.574 436.826 196.542 435.793 195.27 435.793C193.997 435.793 192.966 436.826 192.966 438.1C192.966 439.374 193.997 440.407 195.27 440.407Z"
          fill="#FFA800"
        />
        <path
          d="M234.193 459.327C236.064 459.327 237.581 457.808 237.581 455.934C237.581 454.06 236.064 452.541 234.193 452.541C232.322 452.541 230.805 454.06 230.805 455.934C230.805 457.808 232.322 459.327 234.193 459.327Z"
          fill="#FFA800"
        />
        <path
          d="M210.936 443.528C211.587 442.876 212.671 442.876 213.321 443.528C213.972 444.179 213.972 445.265 213.321 445.916C212.671 446.568 211.587 446.568 210.936 445.916C210.286 445.265 210.286 444.179 210.936 443.528Z"
          fill="#FFA800"
        />
        <path
          d="M182.205 431.831C183.506 430.528 185.647 430.528 186.948 431.831C188.249 433.134 188.249 435.278 186.948 436.581C185.647 437.884 183.506 437.884 182.205 436.581C180.904 435.251 180.904 433.134 182.205 431.831Z"
          fill="#FFA800"
        />
        <path
          d="M259.644 470.429C260.891 469.18 262.897 469.18 264.144 470.429C265.39 471.677 265.39 473.686 264.144 474.935C262.897 476.183 260.891 476.183 259.644 474.935C258.397 473.686 258.397 471.677 259.644 470.429Z"
          fill="#FFA800"
        />
        <path
          d="M297.808 487.528C298.594 486.741 299.895 486.741 300.681 487.528C301.467 488.315 301.467 489.618 300.681 490.405C299.895 491.192 298.594 491.192 297.808 490.405C297.022 489.618 297.022 488.315 297.808 487.528Z"
          fill="#FFA800"
        />
        <path
          d="M366.113 525.8C367.17 524.741 368.905 524.741 369.962 525.8C371.019 526.858 371.019 528.596 369.962 529.654C368.905 530.713 367.17 530.713 366.113 529.654C365.056 528.596 365.056 526.858 366.113 525.8Z"
          fill="#FFA800"
        />
        <path
          d="M375.491 497.219C377.168 497.219 378.527 495.858 378.527 494.179C378.527 492.5 377.168 491.139 375.491 491.139C373.815 491.139 372.456 492.5 372.456 494.179C372.456 495.858 373.815 497.219 375.491 497.219Z"
          fill="#FFA800"
        />
        <path
          d="M334.183 479.412C335.538 478.055 337.707 478.055 339.035 479.412C340.39 480.77 340.39 482.941 339.035 484.271C337.68 485.628 335.511 485.628 334.183 484.271C332.855 482.941 332.855 480.77 334.183 479.412Z"
          fill="#FFA800"
        />
        <path
          d="M367.36 505.526C368.647 505.526 369.691 504.481 369.691 503.192C369.691 501.903 368.647 500.857 367.36 500.857C366.072 500.857 365.029 501.903 365.029 503.192C365.029 504.481 366.072 505.526 367.36 505.526Z"
          fill="#FFA800"
        />
        <path
          d="M40.6077 318.938C41.448 318.096 42.7762 318.096 43.6164 318.938C44.4567 319.779 44.4567 321.109 43.6164 321.951C42.7762 322.792 41.448 322.792 40.6077 321.951C39.7675 321.109 39.7675 319.752 40.6077 318.938Z"
          fill="#FFA800"
        />
        <path
          d="M232.512 419.479C233.921 418.067 236.225 418.067 237.635 419.479C239.044 420.89 239.044 423.197 237.635 424.609C236.225 426.02 233.921 426.02 232.512 424.609C231.102 423.197 231.102 420.89 232.512 419.479Z"
          fill="#FFA800"
        />
        <path
          d="M374.19 517.793C374.415 517.793 374.597 517.611 374.597 517.386C374.597 517.161 374.415 516.979 374.19 516.979C373.966 516.979 373.784 517.161 373.784 517.386C373.784 517.611 373.966 517.793 374.19 517.793Z"
          fill="#FFA800"
        />
        <path
          d="M228.202 435.71C229.639 434.271 231.97 434.271 233.407 435.71C234.843 437.148 234.843 439.483 233.407 440.921C231.97 442.36 229.639 442.36 228.202 440.921C226.766 439.483 226.766 437.148 228.202 435.71Z"
          fill="#FFA800"
        />
        <path
          d="M451.277 551.235C451.603 550.909 452.172 550.909 452.497 551.235C452.822 551.56 452.822 552.13 452.497 552.456C452.172 552.782 451.603 552.782 451.277 552.456C450.925 552.103 450.925 551.56 451.277 551.235Z"
          fill="#FFA800"
        />
        <path
          d="M420.73 545.943C421.733 544.938 423.359 544.938 424.362 545.943C425.365 546.947 425.365 548.576 424.362 549.58C423.359 550.584 421.733 550.584 420.73 549.58C419.727 548.576 419.727 546.947 420.73 545.943Z"
          fill="#FFA800"
        />
        <path
          d="M447.347 557.886C447.736 557.886 448.052 557.57 448.052 557.18C448.052 556.791 447.736 556.475 447.347 556.475C446.958 556.475 446.642 556.791 446.642 557.18C446.642 557.57 446.958 557.886 447.347 557.886Z"
          fill="#FFA800"
        />
        <path
          d="M248.938 501.481C250.24 501.481 251.296 500.424 251.296 499.119C251.296 497.815 250.24 496.758 248.938 496.758C247.635 496.758 246.58 497.815 246.58 499.119C246.58 500.424 247.635 501.481 248.938 501.481Z"
          fill="#FFA800"
        />
        <path
          d="M425.771 536.984C425.961 536.794 426.286 536.794 426.503 536.984C426.72 537.174 426.693 537.5 426.503 537.717C426.313 537.907 425.988 537.907 425.771 537.717C425.581 537.5 425.581 537.201 425.771 536.984Z"
          fill="#FFA800"
        />
        <path
          d="M39.3338 412.829C40.3909 411.771 42.1528 411.771 43.2099 412.829C44.267 413.888 44.267 415.652 43.2099 416.711C42.1528 417.769 40.3909 417.769 39.3338 416.711C38.2767 415.652 38.2767 413.915 39.3338 412.829Z"
          fill="#FFA800"
        />
        <path
          d="M1386.92 343.287C1387.57 342.636 1388.63 342.636 1389.28 343.287C1389.93 343.939 1389.93 344.997 1389.28 345.649C1388.63 346.3 1387.57 346.3 1386.92 345.649C1386.27 344.997 1386.27 343.939 1386.92 343.287Z"
          fill="#FFA800"
        />
        <path
          d="M1357.75 289.541C1358.78 288.51 1360.44 288.51 1361.47 289.541C1362.5 290.573 1362.5 292.228 1361.47 293.26C1360.44 294.291 1358.78 294.291 1357.75 293.26C1356.72 292.256 1356.72 290.573 1357.75 289.541Z"
          fill="#FFA800"
        />
        <path
          d="M1400.2 361.636C1400.44 361.392 1400.85 361.392 1401.09 361.636C1401.34 361.881 1401.34 362.288 1401.09 362.532C1400.85 362.776 1400.44 362.776 1400.2 362.532C1399.96 362.288 1399.96 361.881 1400.2 361.636Z"
          fill="#FFA800"
        />
        <path
          d="M1407.95 378.219C1408.93 377.242 1410.5 377.242 1411.45 378.219C1412.42 379.196 1412.42 380.771 1411.45 381.721C1410.47 382.698 1408.9 382.698 1407.95 381.721C1406.98 380.771 1406.98 379.196 1407.95 378.219Z"
          fill="#FFA800"
        />
      </g>
    </Svg>
  )
}

export default SunburstSvg
