import { Heading, Flex, Text, MetricsCommunityIcon, MetricsChartIcon, MetricsSwapIcon, Box } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import MetricsCard, { MetricsCardProps } from './MetricsCard'
import CalendarIcon from './CalendarIcon'

const Stats = () => {
  const { t } = useTranslation()
  const { theme: propsTheme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const iconProps = {
    color: propsTheme.colors.warning,
    width: '64px',
  }

  const cards: MetricsCardProps[] = [
    {
      headingText: t('%users% users', { users }),
      bodyText: t('in the last 30 days'),
      icon: <MetricsCommunityIcon {...iconProps} />,
    },
    {
      headingText: t('%trades% trades', { trades }),
      bodyText: t('made in the last 30 days'),
      icon: <MetricsSwapIcon {...iconProps} />,
    },
    {
      headingText: t('$%tvl% staked', { tvl: tvlString }),
      bodyText: t('Total Value Locked'),
      icon: <MetricsChartIcon {...iconProps} />,
    },
  ]

  const StyledBox = styled(Box)`
    background: ${({ theme }) => theme.colors.backgroundAlt};
    box-shadow: ${({ theme }) => theme.shadows.default};
    border: 1px solid #dfe0eb;
    border-radius: 4px;
    height: 130px;
    width: 100%;
    max-width: 655px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${({ theme }) => theme.mediaQueries.sm} {
      flex-direction: row;
    }
    text-align: center;
  `

  const IconWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
    ${({ theme }) => theme.mediaQueries.sm} {
      margin-bottom: 0px;
      margin-right: 32px;
    }
  `

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" my="32px">
      <Heading textAlign="center" scale="xl">
        {t('MagicSwap takes care of you.')}
      </Heading>
      <Heading textAlign="center" scale="xl" mb="32px">
        {t('Used by millions.')}
      </Heading>
      <Text textAlign="center" color="textSubtle">
        {t('Convenient and useful for everyone.')}
      </Text>
      <Text textAlign="center" color="textSubtle" mb="72px">
        {t('Just like magic')}
      </Text>
      {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color="textSubtle" mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}

      {/* <Flex flexDirection={['column', null, null, 'row']} style={{ gridGap: 88 }} mt={['88px', null, null, 0]}>
        {cards.map((cardProps, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MetricsCard {...cardProps} key={index} />
          )
        })}
      </Flex> */}
      <StyledBox>
        <IconWrapper>
          <CalendarIcon />
        </IconWrapper>
        <Text weight={500} fontSize="24px" color="#030415">
          {t('Available from September 1st')}
        </Text>
      </StyledBox>
    </Flex>
  )
}

export default Stats
