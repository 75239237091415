import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  const { color, ...rest } = props;
  const pathProps = {
    stroke: color || "",
    strokeWidth: 2,
    strokeLinecap: "round" as const,
    strokeLinejoin: "round" as const,
  };

  return (
    <Svg viewBox="0 0 64 64" fill="none" color="transparent" {...rest}>
      <path d="M7 52H56.2308" {...pathProps} />
      <path d="M7 39.6923L19.3077 21.2308L31.6154 27.3846L43.9231 12L56.2308 24.3077" {...pathProps} />
    </Svg>
  );
};

export default Icon;
