import { TranslateFunction } from 'contexts/Localization/types'
import { buttonVariants } from '@pancakeswap/uikit/src/components/Button/types'
import unserializedTokens from 'config/constants/tokens'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Decentralized and secure, as if you were covered by a magical shield.'),
  bodyText: t('You don`t need to tell about yourself to use the crypto'),
  reverse: false,
  isDark: true,
  type: 'bubble',
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Let crypto work for you.'),
  bodyText: t("You don't have to do anything but earn miraculous passive income."),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: null /* {
    to: 'https://docs.pancakeswap.finance/products/yield-farming',
    text: t('Learn'),
    external: true,
  }, */,
  images: {
    path: '/images/home/earn/',
    attributes: [{ src: 'chart', alt: t('line chart') }],
  },
  noAnimation: true,
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Get millions of gifts from MagicSwap.'),
  bodyText: t('Use your incredible luck and win prizes'),
  isDark: true,
  reverse: false,
  primaryButton: {
    to: `/swap?outputCurrency=${unserializedTokens.cake.address}`,
    text: t('Buy Magic Coin'),
    external: false,
    variant: buttonVariants.WARNING,
  },
  secondaryButton: null,
  images: {
    path: '/images/home/cake/',
    attributes: [{ src: 'world', alt: t('world') }],
  },
})
