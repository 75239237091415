import styled from 'styled-components'
import { Flex, Heading, Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import SunburstSvg from './SunburstSvg'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const StyledSunburst = styled(SunburstSvg)`
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100vw;

  ${({ theme }) => theme.mediaQueries.md} {
    top: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    top: 0;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    top: -16vh;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  overflow: hidden;
`

const Footer = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <BgWrapper>
        <StyledSunburst />
      </BgWrapper>
      <Wrapper>
        <Heading mb="32px" scale="xl" textAlign="center" fontSize="40px">
          {t('Use magic coins')}
        </Heading>
        <Text textAlign="center" color="darkGray">
          {t(
            'The internal currency of our ecosystem MagicDAO will bring you wonderful riches. Trade, earn, get passive income, win',
          )}
          .
        </Text>
        <Text mb="40px" mt="8px" bold>
          {t('Use crypto now')}
        </Text>
        <Link external target="_self" href="# " fontSize="16px" color="text" underline>
          {t('Learn how to start')}
        </Link>
        {!account && <ConnectWalletButton mt="32px" />}
      </Wrapper>
    </>
  )
}

export default Footer
