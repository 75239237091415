import { Button, Flex, Heading, Text, MENU_HEIGHT, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from 'contexts/Localization'
import styled, { keyframes } from 'styled-components'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, 0px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const ellipsesFading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -3px;
  display: flex;
  justify-content: center;
  animation: ${fading} 2.5s ease-in-out infinite;
  animation-delay: 0.33s;
`

const Ellipse1Wrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 80%;
  height: 100%;
  bottom: 0;
  right: 0;
  animation: ${ellipsesFading} 2s ease-in-out infinite;
  animation-delay: 1s;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

// const InnerWrapper = styled.div`
//   position: absolute;
//   width: 100%;
//   height: unset;
//   left: 0;
//   bottom: -3px;
// `

const Ellipse2Wrapper = styled.div`
  position: absolute;
  width: auto;
  right: 0;
  bottom: -3px;
  animation: ${ellipsesFading} 2.5s ease-in-out infinite;
  animation-delay: 0.66s;
`

const WizardWrapper = styled.div`
  display: flex;
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  z-index: 2;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 350px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 350px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 100%;
  }

  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const HeroContent = styled(Flex)<{ menuHeight: number }>`
  position: relative;
  max-width: 1500px;
  height: 100%;
  padding: 100px 16px 0;
  /* align-items: center;
  flex-direction: column;
  justify-content: center; */
  display: grid;
  grid-template-rows: 1fr max-content;
  grid-template-columns: 1fr;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.xs} {
    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    /* grid-template-columns: auto 1fr;
    grid-template-rows: 1fr; */
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    padding: 0 0 0 80px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    padding: 0 0 0 80px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    padding: 0 0 0 164px;
  }
`
// const starsImage: CompositeImageProps = {
//   path: '/images/home/lunar-bunny/',
//   attributes: [
//     { src: 'star-l', alt: '3D Star' },
//     { src: 'star-r', alt: '3D Star' },
//     { src: 'star-top-r', alt: '3D Star' },
//   ],
// }

enum Resolution {
  MD = '1.5x',
  LG = '2x',
}

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isMobile } = useMatchBreakpoints()

  const getImageUrl = (base: string, imageSrc: string, resolution?: Resolution, extension = '.png'): string =>
    `${base}${imageSrc}${resolution ? `@${resolution}${extension}` : extension}`

  const getSrcSet = (base: string, imageSrc: string, extension = '.png') => {
    return `${getImageUrl(base, imageSrc, undefined, extension)} 512w,
  ${getImageUrl(base, imageSrc, Resolution.MD, extension)} 768w,
  ${getImageUrl(base, imageSrc, Resolution.LG, extension)} 1024w,`
  }

  return (
    <>
      <Ellipse1Wrapper>
        <picture>
          <source type="image/webp" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-1', '.webp')} />
          <source type="image/png" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-1')} />
          <img
            src={getImageUrl('/images/home/ellipses/', 'ellipses-1')}
            alt="Ellipses"
            loading="lazy"
            decoding="async"
          />
        </picture>
      </Ellipse1Wrapper>
      <Ellipse2Wrapper>
        <picture>
          <source type="image/webp" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-2', '.webp')} />
          <source type="image/png" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-2')} />
          <img
            src={getImageUrl('/images/home/ellipses/', 'ellipses-2')}
            alt="Ellipses"
            loading="lazy"
            decoding="async"
          />
        </picture>
      </Ellipse2Wrapper>

      <BgWrapper>
        <picture>
          <source type="image/webp" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-3', '.webp')} />
          <source type="image/png" srcSet={getSrcSet('/images/home/ellipses/', 'ellipse-3')} />
          <img
            src={getImageUrl('/images/home/ellipses/', 'ellipses-3')}
            alt="Ellipses"
            loading="lazy"
            decoding="async"
          />
        </picture>
      </BgWrapper>

      <HeroContent id="homepage-hero" menuHeight={MENU_HEIGHT}>
        <Flex flex="1" maxWidth="480px" width="100%" flexDirection="column">
          <Heading fontSize={isMobile ? '40px' : '56px'} color="white">
            {t('Magic on your side')}
          </Heading>
          <Heading fontSize={isMobile ? '40px' : '56px'} color="white" mb={['16px', null, '40px']}>
            {t('Ride the magic')}
          </Heading>
          <Text color="white" lineHeight="28px" mb={['16px', null, '40px']}>
            {t('Trade and earn crypto with a wave of a magic wand')}
          </Text>
          <Flex>
            {!account ? (
              <ConnectWalletButton mr="8px" />
            ) : (
              <NextLinkFromReactRouter to="/swap">
                <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
              </NextLinkFromReactRouter>
            )}
          </Flex>
        </Flex>
        <Flex
          height="100%"
          width="100%"
          flex={[null, null, null, '1']}
          alignItems="flex-end"
          justifyContent="center"
          mb="0"
          position="relative"
        >
          <WizardWrapper>
            <picture>
              <source type="image/webp" srcSet={getSrcSet('/images/home/wizard/', 'wizard', '.webp')} />
              <source type="image/png" srcSet={getSrcSet('/images/home/wizard/', 'wizard')} />
              <img src={getImageUrl('/images/home/wizard/', 'wizard')} alt="Wizard" loading="lazy" decoding="async" />
            </picture>
          </WizardWrapper>
          <StarsWrapper />
        </Flex>
      </HeroContent>
    </>
  )
}

export default Hero
