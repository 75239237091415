import { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Box, SwapVertIcon, IconButton, Grid, Heading } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { DeserializedPool } from 'state/types'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useGetTopFarmsByApr from 'views/Home/hooks/useGetTopFarmsByApr'
import useGetTopPoolsByApr from 'views/Home/hooks/useGetTopPoolsByApr'
import { vaultPoolConfig } from 'config/constants/pools'
import TopFarmPool from './TopFarmPool'

const StyledGrid = styled(Grid)<{ visible?: boolean }>`
  position: absolute;
  padding: 12px 24px;
  grid-template-columns: repeat(2, auto);
  grid-gap: 36px 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(5, auto);
    ${({ visible }) =>
      visible &&
      css`
        &:after {
          content: '';
          display: block;
          inset: 46px 24px auto 24px;
          height: 1px;
          background-color: #ebedf0;
          position: absolute;
        }
      `}
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 32px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-gap: 80px;
  }

  ${({ visible }) =>
    visible &&
    css`
      position: relative;
    `}
`

const StyledFlex = styled(Flex)`
  transition: width 0.5s ease-in-out;
  flex-direction: column;
`
const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.colors.backgroundAlt};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border-radius: 4px;
`

const FarmsPoolsRow = () => {
  const [showFarms, setShowFarms] = useState(false)
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  // const { topFarms } = useGetTopFarmsByApr(isIntersecting)
  const { topPools, hasPools } = useGetTopPoolsByApr(isIntersecting)

  const timer = useRef<ReturnType<typeof setTimeout>>(null)
  const hasFarms = false // topFarms.length > 0
  const startTimer = useCallback(() => {
    if (hasFarms || hasPools) {
      return
    }
    timer.current = setInterval(() => {
      setShowFarms((prev) => !prev)
    }, 6000)
  }, [timer, hasFarms, hasPools])

  // useEffect(() => {
  //   if (!hasFarms) {
  //     setShowFarms(false)
  //   }
  // }, [hasFarms])

  useEffect(() => {
    if (hasPools && hasFarms) {
      startTimer()
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [timer, startTimer, hasFarms, hasPools])

  const getPoolText = (pool: DeserializedPool) => {
    if (pool.vaultKey) {
      return vaultPoolConfig[pool.vaultKey].name
    }

    if (pool.sousId === 0) {
      return t('Manual MagicDao')
    }

    return t('Stake %stakingSymbol% - Earn %earningSymbol%', {
      earningSymbol: pool.earningToken.symbol,
      stakingSymbol: pool.stakingToken.symbol,
    })
  }

  return (
    <Flex flexDirection="column" alignItems="center" ref={observerRef}>
      <StyledFlex flexDirection="column" mt="24px">
        <Flex mb="24px">
          {/*  {hasPools && hasFarms && (
            <>
              <Heading scale="md">{showFarms ? t('Top Farms') : t('Top Pools')}</Heading>
              <IconButton
                variant="text"
                height="100%"
                width="auto"
                onClick={() => {
                  setShowFarms((prev) => !prev)
                  clearInterval(timer.current)
                  startTimer()
                }}
              >
                <SwapVertIcon height="24px" width="24px" color="textSubtle" />
              </IconButton>
            </>
          )}
           */}
          {/* {!hasFarms && <Heading scale="md">{t('Top Pools')}</Heading>}
          {!hasPools && <Heading scale="md">{t('Top Farms')}</Heading>} */}
          <Heading scale="md">{showFarms ? t('Top Farms') : t('Top Pools')}</Heading>
        </Flex>
        <StyledBox height={['390px', null, '130px']}>
          {hasFarms && (
            <StyledGrid visible={showFarms}>
              {/* {topFarms.map((topFarm, index) => (
                <TopFarmPool
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  title={topFarm?.lpSymbol}
                  percentage={topFarm?.apr + topFarm?.lpRewardsApr}
                  index={index}
                  visible={showFarms}
                />
              ))} */}
            </StyledGrid>
          )}

          <StyledGrid visible={!showFarms}>
            {topPools.map((topPool, index) => (
              <TopFarmPool
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={topPool && getPoolText(topPool as DeserializedPool)}
                percentage={topPool?.apr ?? 0}
                index={index}
                visible={!showFarms}
                isLoading={!hasPools}
              />
            ))}
          </StyledGrid>
        </StyledBox>
      </StyledFlex>
    </Flex>
  )
}

export default FarmsPoolsRow
